.top-nav-bar-navigation {
  flex-grow: 1;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  min-width: 200px;

  > label {
    margin: 0px 12px 0px 0px;
    font-weight: normal;
  }

  > .arrow {
    border: solid #616161;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg) translate(1px, 0px);
  }
}

.session-name-input {
  width: 40%;
  margin-left: 13px;
  padding: 0 5px;

  &:focus,
  &:active {
    border: #9999ff solid 1px;
  }
}

.session-name {
  margin-left: 13px;
  display: inline;
}

.top-nav-bar-sessions {
  cursor: pointer;
}

.folder-name {
  padding-left: 20px;
  margin-bottom: -5px;
  margin-right: 10px;
  max-width: 40%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  cursor: pointer;
}
